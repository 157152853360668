








































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
.new-script-managment {
  padding-bottom: 30px;
  .btn-two {
    padding-right: 30px;
    display: flex;
    justify-content: flex-end;
  }
  .no-data-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #a8a8b8;
    margin-bottom: 30px;
  }
  .window-open {
    .header-tag-box {
      display: inline-block;
      width: 4px;
      height: 12px;
      margin-right: 10px;
      background-color: #2821fc;
      border-radius: 4px;
      font-size: 16px;
      font-weight: bold;
      color: #061101;
    }
    .dialog-footer {
      display: flex;
      justify-content: center;
    }
    .shop-table {
      margin: 15px 35px 0 35px;
      border: 1px solid rgb(233, 232, 232);
      width: auto;
      height: 400px;
      overflow-y: auto;
      border-radius: 5px;
      .shop-check {
        display: flex;
        align-items: center;
        margin: 20px;
      }
    }
  }
  .script-container {
    margin-top: 29px;
    height: auto;
    background: #ffffff;
    border-radius: 2px;
    .script-header {
      display: flex;
      align-items: center;
      height: 60px;
      background: #f0f3fc;
      box-sizing: border-box;
    }
    .script-bory-box {
      height: 300px;
      overflow-y: auto;
      .script-body {
        display: flex;
        border: 2px solid #f7f8fc;
        width: auto;
        .script-body-item {
          height: auto;
          padding: 20px 0;
          display: flex;
          align-items: center;
        }
        .script-body-icon {
          height: 60px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-left: 35px;
          .lrline {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            border-left: 2px solid #eee;
            border-bottom: 2px solid #eee;
          }
        }
      }
    }
    .script-footer {
      display: flex;
      border: 2px solid #f7f8fc;
      height: 55px;
      align-items: center;
      justify-content: flex-end;
      padding-right: 12px;
    }
  }
  .script-container {
    margin-top: 29px;
    margin-right: 30px;
    height: 1%;
    background: #ffffff;
    border: 2px solid #f7f8fc;
    border-radius: 2px;
    .script-header {
      display: flex;
      align-items: center;
      height: 60px;
      background: #f0f3fc;
      box-sizing: border-box;
    }
    .script-body {
      display: flex;
      .script-body-item {
        display: flex;
        align-items: center;
        height: 64px;
      }
    }
  }
  .getBack {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
